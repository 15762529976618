import { useNavigation } from '@react-navigation/native';
import { IpadLang, VisitAuthorizationStatusType, VisitsModel } from '@w3lcome/types';
import { Text } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import { colors, fontSizes } from '_/config/theme';
import removeHtmlTags from '_/helpers/removeHtmlTags';
import { textReplace } from '_/helpers/textReplace';
import { useCustomization } from '_/hooks/CustomizationContext';
import { useIntegrations } from '_/hooks/IntegrationsContext';
import { usePrinters } from '_/hooks/PrintersContext';
import { useVisit } from '_/hooks/VisitContext';
import { AppRoute, HomeProps } from '_/navigation/types';
import i18n from '_/services/locale';
import Constants from 'expo-constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';

import CustomMessage from './CustomMessage';

const CheckinMessageScreen: React.FC = () => {
  const { customization } = useCustomization();

  const { visit, checkinVisit, isPrintVisitBadge } = useVisit();
  const { printVisitBadge } = usePrinters();
  const { runCheckinIntegrations } = useIntegrations();
  const [startTimer, setStartTimer] = useState(false);
  const [createdVisit, setCreatedVisit] = useState<VisitsModel>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { t } = useTranslation();
  const navigation = useNavigation<HomeProps>();

  useEffect(() => {
    async function checkin() {
      if (isPrintVisitBadge) {
        if (Constants.expoConfig?.extra?.checkCardNumberToPrintBadge === 'true') {
          if (visit?.cardNumber) {
            printVisitBadge({ ...visit, checkinAt: new Date() });
          }
        } else {
          printVisitBadge({ ...visit, checkinAt: new Date() });
        }
      }

      runCheckinIntegrations(visit);

      try {
        const value = await checkinVisit(visit);
        setCreatedVisit(value);
      } catch (error) {
        setErrorMessage(t('checkinScreen.checkinError'));
      }
      setStartTimer(true);
    }

    checkin();
  }, []);

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (startTimer) {
      timerId = setTimeout(() => {
        navigation.navigate(AppRoute.HOME);
      }, (customization?.ipadWelcomeDuration || 6) * 1000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [startTimer]);

  const getWelcomeMessage = useCallback(() => {
    const { ipadWelcomeAwaitingAuthorizationMessage, ipadWelcomeMessage, authorizationEnabled } =
      customization || {};

    const hasMessagePreRegistrationAwaitingAuthorized = !!removeHtmlTags(
      ipadWelcomeAwaitingAuthorizationMessage ?? ''
    );

    if (hasMessagePreRegistrationAwaitingAuthorized && authorizationEnabled) {
      if (visit.meeting) {
        return visit.authorizationStatus === VisitAuthorizationStatusType.AWAITING
          ? ipadWelcomeAwaitingAuthorizationMessage
          : ipadWelcomeMessage;
      }

      return ipadWelcomeAwaitingAuthorizationMessage;
    }

    return ipadWelcomeMessage;
  }, [customization, visit]);

  const ipadWelcomeMessage = useMemo(() => {
    const welcomeMessage = getWelcomeMessage();

    return textReplace(
      welcomeMessage || '',
      i18n.language as IpadLang,
      customization,
      createdVisit || visit
    );
  }, [customization, visit, createdVisit]);

  return (
    <View style={styles.container}>
      <ContainerWidthLimit>
        <View style={styles.content}>
          <View style={{ alignItems: 'center' }}>
            {!errorMessage && (
              <>
                <Text size={fontSizes.xxxl}>{t('Welcome')}</Text>
                <Text fontType="bold" size={fontSizes.xxxl}>
                  {visit.name}
                </Text>
              </>
            )}
          </View>
          <CustomMessage
            error={errorMessage}
            message={ipadWelcomeMessage}
            loading={!errorMessage && !createdVisit}
          />
        </View>
      </ContainerWidthLimit>
    </View>
  );
};

export default CheckinMessageScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    alignItems: 'center',
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingHorizontal: 64,
    textAlign: 'center',
  },
});
