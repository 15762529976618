import { colors, fontFamily, fontSizes } from '_/config/theme';
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, View, useWindowDimensions, Image } from 'react-native';
import QRCodeReaderWeb from 'react-qr-reader';
import Text from '../Text';
import { useTranslation } from 'react-i18next';
import { images } from '_/assets';
import { Camera, CameraType } from 'expo-camera/build/legacy';
import { BarCodeEvent } from 'expo-barcode-scanner';
import { useIsFocused } from '@react-navigation/native';

interface QrCodeCheckinAtHomeProps {
  isScannerEnabled: boolean;
  onScan: (data: string) => void;
}

const QrCodeCheckinAtHome: React.FC<QrCodeCheckinAtHomeProps> = ({ isScannerEnabled, onScan }) => {
  const { width, height } = useWindowDimensions();
  const [cameraKey, setCameraKey] = useState<number>(Date.now());
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      setCameraKey(Date.now());
    }
  }, [isFocused]);

  const { t } = useTranslation();

  async function handleBarCodeScanned({ data }: BarCodeEvent) {
    if (!isScannerEnabled) {
      return;
    }

    if (data) {
      onScan(data);
    }
  }

  async function handleBarCodeScannedWeb(data: string) {
    if (!isScannerEnabled) {
      return;
    }

    if (data) {
      onScan(data);
    }
  }

  const isPortrait = height > width;

  return (
    <View
      style={{
        ...styles.container,
        width: isPortrait ? width * 0.7 : width / 2 + 24,
      }}
    >
      <View style={styles.content}>
        <View style={styles.cameraContainer}>
          {Platform.OS === 'web' ? (
            <QRCodeReaderWeb
              onError={() => null}
              showViewFinder={false}
              delay={300}
              onScan={handleBarCodeScannedWeb}
              style={{ width: isPortrait ? 200 : 150, height: isPortrait ? 200 : 150 }}
            />
          ) : (
            <Camera
              key={cameraKey}
              onBarCodeScanned={handleBarCodeScanned}
              type={CameraType.front}
              style={styles.camera}
              barCodeScannerSettings={{
                barCodeTypes: ['qr'],
              }}
            />
          )}
          <View style={styles.overlay}>
            <Image
              source={images.scanIcon}
              style={{
                width: isPortrait ? 150 : 125,
                height: isPortrait ? 150 : 125,
                opacity: 0.5,
              }}
            />
          </View>
        </View>
        <View style={{ alignItems: isPortrait ? 'flex-start' : 'center' }}>
          <Text style={styles.text}>{t('preRegistredVisitor')}</Text>
          <Text style={{ ...styles.text, width: isPortrait ? 200 : 400 }}>
            {t('showQRCodeToCamera')}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default QrCodeCheckinAtHome;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 24,
    justifyContent: 'space-around',
    width: '100%',
  },
  cameraContainer: {
    marginVertical: 32,
    borderRadius: 8,
    overflow: 'hidden',
    marginRight: 48,
    backgroundColor: colors.white,
    shadowColor: colors.light2,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 1,
    shadowRadius: 32.0,
    elevation: 10,
  },
  camera: {
    width: 150,
    height: 150,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: fontSizes.lg2,
    fontFamily: fontFamily.bold,
  },
});
