import axios from 'axios';
import appConfig from '_/config/app';

class HikiCentralApi {
  private serverAdress = process.env.HIKICENTRAL_ADRESS;
  private port = process.env.HIKICENTRAL_PORT;
  private sendFacePath = process.env.HIKICENTRAL_SEND_FACE_PATH;

  sendFacePicture = async (base64Image: string) => {
    try {
      if (!this.serverAdress || !this.port || !this.sendFacePath) {
        throw new Error('HikiCentralApi - Missing environment variables');
      }

      const { data } = await axios.post(
        `https://${this.serverAdress}:${this.port}${this.sendFacePath}`,
        {
          // TODO: Check this values and if this is necessary with the company backend team (faceInfo, faceGroupIndexCode)
          // faceInfo: {
          //   personGivenName: visitName,
          //   personFamilyName: visitLastName,
          // },
          faceGroupIndexCode: '1',
          facePic: {
          faceBinaryData: base64Image,
        } },
        {
          timeout: appConfig.apiTimeout,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      return data;
    } catch (error) {
      throw error;
    }
  };
}

export default new HikiCentralApi();
