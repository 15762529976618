import { ipadLogsApi } from '_/services/api';
import { useAuth } from './AuthContext';
import { useVisit } from './VisitContext';
import { VisitsModelCheckin } from '_/interfaces/VisitsModelCheckin';

export const useDetailedVisitFlowLogging = () => {
  const { ipad } = useAuth();

  const { visit, setVisit } = useVisit();

  function getCheckInStartTime(currentVisitor: Partial<VisitsModelCheckin>) {
    if (!ipad?.enableDetailedVisitFlowLogging) return;

    setVisit({ metadata: { ...currentVisitor.metadata, startCheckin: new Date() } });
  }

  function inactiveVisitLog() {
    if (!ipad?.enableDetailedVisitFlowLogging) return;

    ipadLogsApi.insert({
      companyId: ipad?.companyId,
      ipadId: ipad?.id,
      type: 'Inactive visit',
      message: `VISITOR: ${JSON.stringify(visit)}
        -
        WARNING: Inactive visit`,
    });
  }

  return {
    getCheckInStartTime,
    inactiveVisitLog,
  };
};
