import { BadgeConfig } from '@w3lcome/types';
import { colors } from '_/config/theme';
import { badgeScale } from '_/constants/badgeScale';
import { BadgeInit } from '_/interfaces/BadgeInit';

export const paper62mmx100mm: BadgeInit = ({ visit, field1, field2, field3, image = '' }) => {
  const paper62mmx100mm: BadgeConfig = {
    width: 1000 * badgeScale,
    height: 620 * badgeScale,
    fields: [
      {
        type: 'text',
        subType: 'text',
        value: visit.name ?? '',
        styles: {
          top: 32 * badgeScale,
          left: 0 * badgeScale,
          width: 1000 * badgeScale,
          height: 100 * badgeScale,
          fontSize: 82 * badgeScale,
          fontWeight: 'bold',
          textAlign: 'center',
        },
      },
      {
        type: 'text',
        subType: 'text',
        value: '',
        styles: {
          top: 224 * badgeScale,
          left: 64 * badgeScale,
          width: 288 * badgeScale,
          height: 288 * badgeScale,
          borderRadius: 8,
          borderWidth: 1,
          borderColor: colors.light0,
        },
      },
      {
        type: 'image',
        subType: 'image',
        value: image,
        styles: {
          top: 240 * badgeScale,
          left: 80 * badgeScale,
          width: 256 * badgeScale,
          height: 256 * badgeScale,
          resizeMode: 'contain',
        },
      },
      {
        type: 'text',
        subType: 'text',
        value: field1.title,
        styles: {
          top: 164 * badgeScale,
          left: 420 * badgeScale,
          width: 580 * badgeScale,
          height: 100 * badgeScale,
          fontSize: 40 * badgeScale,
          color: colors.dark3,
        },
      },
      {
        type: 'text',
        subType: 'text',
        value: field1.value,
        styles: {
          top: 216 * badgeScale,
          left: 420 * badgeScale,
          width: 580 * badgeScale,
          height: 100 * badgeScale,
          fontSize: 52 * badgeScale,
        },
      },
      {
        type: 'text',
        subType: 'text',
        value: field2.title,
        styles: {
          top: 300 * badgeScale,
          left: 420 * badgeScale,
          width: 580 * badgeScale,
          height: 100 * badgeScale,
          fontSize: 40 * badgeScale,
          color: colors.dark3,
        },
      },
      {
        type: 'text',
        subType: 'text',
        value: field2.value,
        styles: {
          top: 352 * badgeScale,
          left: 420 * badgeScale,
          width: 580 * badgeScale,
          height: 100 * badgeScale,
          fontSize: 52 * badgeScale,
        },
      },
      {
        type: 'text',
        subType: 'text',
        value: field3.title,
        styles: {
          top: 440 * badgeScale,
          left: 420 * badgeScale,
          width: 580 * badgeScale,
          height: 100 * badgeScale,
          fontSize: 40 * badgeScale,
          color: colors.dark3,
        },
      },
      {
        type: 'text',
        subType: 'text',
        value: field3.value,
        styles: {
          top: 492 * badgeScale,
          left: 420 * badgeScale,
          width: 580 * badgeScale,
          height: 100 * badgeScale,
          fontSize: 52 * badgeScale,
        },
      },
    ],
  };

  return paper62mmx100mm;
};
