import { PrintersModel, Lang, BadgeConfig } from '@w3lcome/types';
import appConfig from '_/config/app';
import { getDateLocale } from '_/helpers/getDateLocale';
import { BadgeFieldType } from '_/interfaces/BadgeFieldType';
import { BadgeImageType } from '_/interfaces/BadgeImageType';
import { PrinterPaper } from '_/interfaces/PrinterPaper';
import { VisitsModelCheckin } from '_/interfaces/VisitsModelCheckin';
import { format, parseISO } from 'date-fns';
import QRCode from 'qrcode-generator';

import { badges, testBadges } from './badges';
import { downloadAndConvertToBase64 } from './downloadAndConvertToBase64';
import { TFunction } from 'i18next';
import { BadgeFieldArg } from '_/interfaces/BadgeInit';

function generateQRCode(value: string) {
  const qrcode = QRCode(0, 'L');
  qrcode.addData(value);
  qrcode.make();
  return qrcode.createDataURL(6, 2);
}

async function getBadgeImage(
  visit: Partial<VisitsModelCheckin>,
  badgeImage: string | null,
  qrcodeURL: string | null,
  companyLogo?: string | null
): Promise<string> {
  if (visit.cardNumber) {
    return generateQRCode(visit.cardNumber);
  }

  if (badgeImage === BadgeImageType.Logo) {
    return downloadAndConvertToBase64(companyLogo);
  }

  if (badgeImage === BadgeImageType.QRCode) {
    const qrcode = qrcodeURL ?? `${appConfig.company}://visit/${visit.id}`;
    return generateQRCode(qrcode);
  }

  if (badgeImage === BadgeImageType.Picture) {
    if (visit.picture?.base64) {
      const pictureBase64 = visit.picture.base64;
      if (pictureBase64.includes(';base64,')) {
        return pictureBase64;
      } else {
        const prefix = 'data:image/jpeg;base64,';
        return prefix + pictureBase64;
      }
    } else if (visit.pictureMediumUrl) {
      return downloadAndConvertToBase64(visit.pictureMediumUrl);
    }
  }

  return '';
}

function getBadgeField(
  visit: Partial<VisitsModelCheckin>,
  field: string | null,
  printer: PrintersModel,
  lang?: Lang | null,
  translateFunction?: TFunction
) {
  const badgeField = {
    title: translateFunction?.(`badgePrint.${field}`) || field,
    value: '',
  } as BadgeFieldArg;
  const locale = getDateLocale(lang);

  switch (field) {
    case BadgeFieldType.Host:
      badgeField.value = visit.host?.name ?? '';
      break;
    case BadgeFieldType.MeetingTitleDate:
      if (visit.meeting) {
        badgeField.value = `${visit.meeting.title} (${format(
          parseISO(visit.meeting.start as any),
          'Pp',
          { locale }
        )})`;
      }
      break;
    case BadgeFieldType.MeetingTitle:
      badgeField.value = visit.meeting?.title ?? '';
      break;
    case BadgeFieldType.MeetingLocation:
      badgeField.value = visit.meeting?.location ?? '';
      break;
    case BadgeFieldType.MeetingLocationDate:
      if (visit.meeting) {
        badgeField.value = `${visit.meeting.location} (${format(
          parseISO(visit.meeting.start as any),
          'Pp',
          { locale }
        )})`;
      }
      break;
    case BadgeFieldType.ArrivedDate:
    case BadgeFieldType.CheckinAt:
      if (!!visit.checkinAt) {
        if (typeof visit.checkinAt === 'string') {
          badgeField.value = format(parseISO(visit.checkinAt), 'Pp', { locale });
        } else {
          badgeField.value = format(visit.checkinAt as any, 'Pp', { locale });
        }
      } else if (visit.meeting) {
        badgeField.value = translateFunction?.('badgePrint.scheduled') ?? '';
      }
      break;
    case BadgeFieldType.Message:
      badgeField.value = printer.badgeMessage ?? '';
      break;
    default:
      if (field && visit[field.toLowerCase() as keyof VisitsModelCheckin]) {
        badgeField.value = (visit[field.toLowerCase() as keyof VisitsModelCheckin] as string) ?? '';
      }
      break;
  }

  return badgeField;
}

export async function getBadge(
  visit: Partial<VisitsModelCheckin>,
  printer: PrintersModel,
  lang?: Lang | null,
  companyLogo?: string | null,
  translateFunction?: TFunction
): Promise<BadgeConfig | undefined> {
  const badgeConfig = printer.badgeConfig;

  if (!badgeConfig) {
    const image = await getBadgeImage(visit, printer.badgeImage, printer.qrcodeURL, companyLogo);
    const field1 = getBadgeField(visit, printer.badgeFirstField, printer, lang, translateFunction);
    const field2 = getBadgeField(visit, printer.badgeSecondField, printer, lang, translateFunction);
    const field3 = getBadgeField(visit, printer.badgeThirdField, printer, lang, translateFunction);

    const badgeInit = badges[printer.paper as PrinterPaper];

    return badgeInit?.({ visit, field1, field2, field3, image });
  }

  const fieldPromises = badgeConfig.fields.map(async (field) => {
    if (field.type === 'image') {
      const image = await getBadgeImage(visit, field.subType, printer.qrcodeURL, companyLogo);
      return { ...field, value: image };
    } else if (field.type === 'text') {
      const badgeField = getBadgeField(visit, field.subType, printer, lang);
      return { ...field, value: badgeField.value as string };
    }
    return field;
  });

  const updatedField = await Promise.all(fieldPromises);
  badgeConfig.fields = updatedField;

  return badgeConfig;
}

export function getTestBadge(printer: PrintersModel): BadgeConfig | undefined {
  return testBadges[printer.paper as PrinterPaper];
}
